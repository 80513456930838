import Parse from 'parse';
import { useState, useEffect} from "react";
import { useNavigate, useParams  } from "react-router-dom"
import { getConfig } from "../services/config"
import { NavBar } from "../components/navbar"
import { Footer } from "../components/footer"
import { GridList } from "../components/grid"
import { getSingleListPage, getCategories } from "../services/api"
import { scrollToTop } from "../services/helper"
import { Loader } from "../components/loader"
import getStr from "../data/strings";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Form from 'react-bootstrap/Form';

export default function GridPage() 
{
  const { list_id } = useParams();
  const navigate = useNavigate();
  const [list, setList] = useState({items:[]})
  const [categories, setCategories] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  async function setInitScreen(listObjectId)
  {
    setIsLoading(true)
    const list_data = await getSingleListPage(listObjectId)
    const categories_data = await getCategories()
    setIsLoading(false)
    let _categories = []
    if (list_data.categories.length === 0) // if there are not define categories, use all system categories
    {
      categories_data.unshift({
        name:getStr("category_all"),
        objectId:"default"
      })
      setCategories(categories_data)
    }
    else // if there is a category in the list, create list filtereb by available categories in the list
    {
      for (let i = 0; i < list_data.categories.length; i++)
      {
        for (let j = 0; j < categories_data.length; j++)
        {
          if (list_data.categories[i] === categories_data[j].objectId)
          {
            _categories.push(categories_data[j])
          }
        }
      }
      setCategories(_categories)
    }
    if (!list_data.isError)
    {
      setList(list_data)  
      scrollToTop()
    }
    else 
    {
      navigate("/page/authenticate")
    }
  }

  async function setScreen(listObjectId, filterByCategory)
  {
    setIsLoading(true)
    const list_data = await getSingleListPage(listObjectId, filterByCategory)
    setIsLoading(false)
    if (!list_data.isError)
    {
      setList(list_data)  
      scrollToTop()
    }
    else 
    {
      navigate("/page/authenticate")
    }
  }

  function onChangeFilter(event)
  {
    setScreen(list_id, event.target.value)
  }

  function goToAsset(object_id)
  {
    navigate("/asset/"+object_id)
  }

  useEffect(() => 
  {
    if (list_id)
    {
      setInitScreen(list_id)
    }

  }, [])

  return (
    <>
      <NavBar/>
      <Container style={{minHeight:"960px", marginTop:"16px"}}>
        {isLoading?<Loader/>:<> 
          <Row style={{marginTop: "0px"}}>
            {categories && categories.length>1?<Col sm style={{ maxWidth: "240px" }}> 
              <Form.Select aria-label="Default select example" style={{backgroundColor:"gray", borderColor:"gray"}} onChange={onChangeFilter}>
                  {categories.map((item)=>(
                    <option key={item.objectId} value={item.objectId}>{item.name}</option>
                  ))}
              </Form.Select>
            </Col>:null} 
          </Row>

          {list&&list.items.length>0?<GridList list={list} onClickCallback={goToAsset} />:<h1>lista jest pusta, wybierz inną kategorie</h1>}
        </>}
      </Container>
      <Footer/>
    </>
  );
}