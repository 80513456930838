import styled from "styled-components"
import { TileVodPoster, TileLiveEvent } from "../components/tiles"
import { Category } from "../components/listCategory"
import Carousel from 'react-bootstrap/Carousel';
import Container from 'react-bootstrap/Container';

const GridPoster = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(205px, 205px));
  gap: 12px;
`

const GridBanner = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(312px, 312px));
  gap: 12px;
  margin-bottom: 16px
`

export function Lists({lists, onTileClickCallback, goToList})
{

	return <div style={{minHeight:"960px"}}>
      {lists.map((list) => (
        <div key={list.objectId}>
            {list.tileType==="poster"?<Container >
                <Category name={list.name} objectId={list.objectId} goToList={goToList} />
                <GridPoster>
                  {list.items.map((item) => (
                    <TileVodPoster item={item} key={item.objectId} onClickCallback={onTileClickCallback} />
                  ) ) }
                </GridPoster>
              </Container>:null}
            {list.tileType==="image"?<Container>
              <Category name={list.name} objectId={list.objectId} goToList={goToList} />
                <GridBanner>
                {list.items.map((item) => (
                  <TileLiveEvent item={item} key={item.objectId} onClickCallback={onTileClickCallback} />
                ) ) }
              </GridBanner>
            </Container>:null}
  
             {list.tileType==="banner_image"||list.tileType==="banner_poster"?<Carousel fade interval={4000}>
              {list.items.map((item) => (
                <Carousel.Item key={item.objectId} onClick={()=>{onTileClickCallback(item.objectId)} } style={{marginBottom:"32px", maxHeight:"640px"}}>
                  <img
                    className="d-block w-100"
                    src={item.image.url}
                    alt={item.title}
                  />
                  <Carousel.Caption>
                    <h3>{item.title}</h3>
                    <p>{item.description}</p>
                  </Carousel.Caption>
                </Carousel.Item>
                // <TileLiveEvent item={item} key={item.objectId} onClickCallback={onTileClickCallback} />
              ) ) }
            </Carousel>:null}
        </div>
      ) ) }
  </div>
}