import { isFeatureAvailable } from "../custom/config"
import { isUserLoggedIn } from "../services/user"
import { getUserPurchasedProducts } from "../services/api"

export const showAssetButton = async(assetProducts, assetObjectId, isMainMediafileAvailable, canPurchase) => 
{
  if (isMainMediafileAvailable)
  {
    if (isFeatureAvailable("forceAuthenticationToWatch"))
    {
      if (isUserLoggedIn())
      {
        if (assetProducts && assetProducts.length > 0) // asset required product
        {
          // get user products
          // compare with asset products
          const userProducts = await getUserPurchasedProducts()
          if (userProducts && userProducts.length > 0)
          {
            for (let i = 0; i < userProducts.length; i ++)
            {
              const product = userProducts[i].product
              // console.log(product.get("whatType"))
              if (product.whatType === "svod")
              {
                 for (let j = 0; j < assetProducts.length; j ++)
                 {
                   if (product.objectId === assetProducts[j]) // user has product required by asset
                   {
                     return "play"
                   }
                 }
              }
              else if (product.whatType === "tvod")
              {
                if (userProducts[i].asset)
                {
                  if (userProducts[i].asset.objectId === assetObjectId)
                  {
                    return "play"
                  }
                }
              }
            }
            return "buy"
          }
          else  // user does not have this product
          {
            return "buy"
          }
        }
        else // asset does not require any product  
        {
          return "play"
        }
      }
      else  
      {
        return "login"
      }
    }
    else  
    {
      return "play"
    }
  }
  else if (canPurchase) // manifest is not available but asset can be purchased.
  {
    if (isFeatureAvailable("forceAuthenticationToWatch"))
    {
      if (isUserLoggedIn())
      {
        if (assetProducts && assetProducts.length > 0) // asset required product
        {
          // get user products
          // compare with asset products
          const userProducts = await getUserPurchasedProducts()
          if (userProducts && userProducts.length > 0)
          {
            for (let i = 0; i < userProducts.length; i ++)
            {
              const product = userProducts[i].product
              // console.log(product.get("whatType"))
              if (product.whatType === "svod")
              {
                 for (let j = 0; j < assetProducts.length; j ++)
                 {
                   if (product.objectId === assetProducts[j]) // user has product required by asset
                   {
                     return "has_access"
                   }
                 }
              }
              else if (product.whatType === "tvod")
              {
                if (userProducts[i].asset)
                {
                  if (userProducts[i].asset.objectId === assetObjectId)
                  {
                    return "has_access"
                  }
                }
              }
            }
            return "buy"
          }
          else  // user does not have this product
          {
            return "buy"
          }
        }
        else // asset does not require any product  
        {
          return "disable"
        }
      }
      else  
      {
        return "login"
      }
    }
    else  
    {
      return "disable"
    }
  }
  else  
  {
    return "disable"
  }
};
