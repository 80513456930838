
import { useState, useEffect} from "react";
import { useNavigate  } from "react-router-dom"
import { getUserTransactionStatus, getUserPurchasedProducts } from "../services/api"
import getStr from "../data/strings";

import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';

export default function PaymentError() 
{
  const navigate = useNavigate()  

  function exit()
  {
    const assetObjectId = window.localStorage.getItem("lastVisitedAssetObjectId")
    if (assetObjectId)
      {
        navigate("/asset/" + assetObjectId)
        window.localStorage.removeItem("lastVisitedAssetObjectId")
      }
      else  
      {
        navigate("/page/myaccount")
      }
  }


  useEffect(() => 
  {
    localStorage.removeItem("lastTransactionObjectId") 
  }, [])

  return (
    <Container>
    <div style={{textAlign:"center", marginTop:"128px"}}>
      <img
          src="../custom_images/logo_splash.png"
          width="120"
          height="120"
          // className="d-inline-block align-top"
          alt="logo"
          style={{display: "block", marginLeft: "auto", marginRight: "auto", marginTop: "128px"}}
      />
      <h1>
        {getStr("page_paymenterror_info_1")}
      </h1>
      <h4>
         {getStr("page_paymenterror_info_2")}
      </h4>

      <Button variant="light" onClick={exit}>{getStr("btn_close")}</Button>
      </div>
    </Container>
  );
}