import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";

// init 
import "./custom/config";

import Start from './pages/Start';
import Lists from './pages/Lists';
import List from './pages/List';
import Grid from './pages/Grid';
import Asset from './pages/Asset';
import Products from './pages/Products';
import Authenticate from './pages/Authenticate';
import MyAccount from './pages/MyAccount';
import ThankYou from "./pages/ThankYou";
import PaymentError from "./pages/PaymentError"
import TvChannelsGrid from "./pages/TvChannelsGrid"
import Search from "./pages/Search"
import StaticText from './pages/StaticText';

import { createGlobalStyle } from "styled-components"
import CookieConsent from 'react-cookie-consent-notification';

import Parse from 'parse';

// import TagManager from 'react-gtm-module'

import {color_primary, color_accent, main_font, deployment_type} from "./styles/globalstyle";

Parse.initialize("q8eE30siX4LIMvZ7dKJxsS7vO11p4FqZjPXI4b6M", "NysMqdAtcim4JndsFl3rIX1huJllnUmtnGeHreW9");
Parse.serverURL = "https://streamingapp.b4a.io/";
Parse.enableLocalDatastore()

// DEV
// Parse.initialize("PAHRoleN7bsZBErF69sRYLwHTLFrQpxcRN5ZyRbB", "94DUOe5j8ZM2k8uisA8TnLboesbhaatcJB2tGOoe");
// Parse.serverURL = "https://ziodev.b4a.io/";

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    font-family: ${main_font};
    background-color: rgba(20, 20, 20, 255);
  }
  
  p {
    font-family: ${main_font};
    font-size: 16px;
    font-weight: 400;
    color: rgba(250, 250, 250, 255);
  }
  
  h1 {
    font-family: ${main_font};
    font-size: 28px;
    font-weight: 600;
    margin-top: 24px;
    margin-bottom: 16px;
    color: rgba(250, 250, 250, 255);
  }

  h2 {
    font-family: ${main_font};
    font-size: 24px;
    font-weight: 600;
    margin-top: 24px;
    margin-bottom: 16px;
    color: rgba(250, 250, 250, 255);
  }

  h3 {
    font-family: ${main_font};
    font-size: 20px;
    font-weight: 600;
    margin-top: 24px;
    margin-bottom: 16px;
    color: rgba(250, 250, 250, 255);
  }

  h4 {
    font-family: ${main_font};
    color: gray;
    font-size: 18px;
    font-weight: 600;
    margin-top: 24px;
    margin-bottom: 16px;
    color: rgba(250, 250, 250, 255);
  }
`


export default function App() 
{

  return (
    <>
     
      <Router>
        <GlobalStyle/>
          <div>
            <Routes>
              <Route path="/" exact element={<Start/>} />
              <Route path="/lists/:page_id" exact element={<Lists/>} />
              <Route path="/list/:page_id" exact element={<List/>} />
              <Route path="/grid/:list_id" exact element={<Grid/>} />
              <Route path="/asset/:asset_id" exact element={<Asset/>} />
              <Route path="/page/products" exact element={<Products/>} />
              <Route path="/page/authenticate" exact element={<Authenticate/>} />
              <Route path="/page/myaccount" exact element={<MyAccount/>} />
              <Route path="/page/thankyou" exact element={<ThankYou/>} />
              <Route path="/page/paymenterror" exact element={<PaymentError/>} />
              <Route path="/channels/grid" exact element={<TvChannelsGrid/>} />
              <Route path="/search" exact element={<Search/>} />
              <Route path="/info/:page_id" exact element={<StaticText/>} />
            </Routes>
          </div>
        </Router>
        <CookieConsent 
          background={'#ffffff'}
          bottomPosition={true}
          buttonText={'OK'}
          buttonBackground={'#ffffff'}
          buttonBorder={"0px"}
          buttonColor={'#000000'}
          buttonFontSize={22}
          color={'#000000'}
          // consentFunction={checkStatus}
          padding={20}
        >
          This page is using cookies to provide service properly. Further use of this page means that you accept the use od cookies. <a href={'/info/privacy_policy'} style={{ color: '#000000' }}>Privacy Policy</a>
        </CookieConsent>
    </>
  );
}
