import { useState, useEffect} from "react";
import { useNavigate  } from "react-router-dom"
import { NavBar } from "../components/navbar"
import { logoutUser, getUserLogin } from "../services/user"
import { getUserPurchasedProducts } from "../services/api"
import { timestampDateToString } from "../services/helper"
import getStr from "../data/strings";

import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

export default function Authenticate() 
{
  const navigate = useNavigate()  
  const [username, setUsername] = useState("")
  const [products, setProducts] = useState([])

  async function logOutUser()
  {
    await logoutUser()
    navigate(-1)
  }

  function goToAsset(assetObjectId)
  {
    navigate("/asset/"+assetObjectId)
  }

  async function getProducts()
  {
    const products_result = await getUserPurchasedProducts(true)
    setProducts(products_result)
  }

  useEffect(() => 
  {
    const user_login = getUserLogin()
    if (user_login)
    {
      setUsername(user_login)
      getProducts()
    }
  }, [])

  return (
    <>
    <NavBar/>
      
      <Container> 
        <h2 style={{marginTop:"40px"}}>
          {getStr("page_myaccount_mylogin")} {username}
        </h2>
        <h4>{getStr("page_myaccount_myproducts")}</h4>
        {products.map((product) => (
          <Card key={product.id} style={{maxWidth:"480px"}} bg="dark" text="white">
            <Card.Body>
              <Card.Title>{product.product.name}</Card.Title>
              <Card.Text>
                {product.price/100} zł
              </Card.Text>
              {product.asset?<>
                <Card.Text>{getStr("page_myaccount_youhaveaccess")}<b>{product.asset.title}</b></Card.Text>
                <Button variant="primary" onClick={()=>{goToAsset(product.asset.objectId)}}>{getStr("btn_go")}</Button>
              </>:null}
            </Card.Body>
            {product.expirationDate?<Card.Footer>
              <small className="text-muted">
                ważne do: {timestampDateToString(product.expirationDate)}
              </small>
            </Card.Footer>:null}
          </Card>
        ))}
        <Button onClick={logOutUser} style={{margin:"16px 0 0 0"}} >{getStr("btn_logout")}</Button>
      </Container>
    </>
  );
}