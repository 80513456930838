import { useState, useEffect} from "react";
import { useNavigate  } from "react-router-dom"
import { getUserTransactionStatus, getUserPurchasedProducts } from "../services/api"
import getStr from "../data/strings";

import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';

export default function ThankYou() 
{
  const navigate = useNavigate()  
  const [paymentStatusMessage, setPaymentStatusMessage] = useState(getStr("page_thankyou_info_payment_processing"))

  function exit(assetObjectId)
  {
    if (assetObjectId)
    {
      navigate("/asset/" + assetObjectId)
    }
    else  
    {
      navigate("/page/myaccount")
    }
  }

  async function checkPaymentStatus()
  {
    const transactionObjectId =  window.localStorage.getItem("lastTransactionObjectId");
    if (transactionObjectId && transactionObjectId !== "")
    {
      const result = await getUserTransactionStatus(transactionObjectId)
      if (result.paymentStatus === "paid")
      {
         // remove reference
        setPaymentStatusMessage(getStr("page_thankyou_info_payment_completed"))
        await getUserPurchasedProducts(true) // refresh cached data for user products
        setTimeout(()=>{window.localStorage.removeItem("lastTransactionObjectId"); exit(result.assetObjectId) }, 1000) // exit after few seconds for better UX
      }
    }
    else  
    {
      exit()
    }
  }

  useEffect(() => 
  {
    let _interval = setInterval(checkPaymentStatus, 1000*10)
    return () => 
    {
      clearTimeout(_interval)
     }
  }, [])

  return (
    <Container>
    <div style={{textAlign:"center", marginTop:"128px"}}>
      <img
          src="../custom_images/logo_splash.png"
          width="120"
          height="120"
          // className="d-inline-block align-top"
          alt="logo"
          style={{display: "block", marginLeft: "auto", marginRight: "auto", marginTop: "128px"}}
      />
      <h1>
        {getStr("page_thankyou_info_title")}
      </h1>
      <h4>
         {paymentStatusMessage}
      </h4>
      <Spinner animation="grow" style={{display: "block", marginLeft: "auto", marginRight: "auto", marginTop: "16px"}}/>
      </div>
    </Container>
  );
}