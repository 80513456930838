let features = {
  forceAuthenticationToWatch: true,
  useAuthentication: true,
  useUserProfiles: true,
  useUserRecommendations: true,
  useIpRestriction: true,
  forceDeviceRegistrationToWatch:true,
  mobileAppsAvailable:true,
  videoStatistics:true,
}

const config = {
  appName: "streaming app",
  appStoreUrl:"https://www.apple.com/pl/app-store/",
  googlePlayUrl:"http://googleplay.com/",
  searchTileType:"poster",
  defaultUiLanguage: "EN"
}

const custom = {
  castRole:[{name:"Reżyseria",codename:"director"},{name:"Obsada",codename:"actor"},{name:"Scenariusz",codename:"writer"}],
  registrationFields:[{codename:"name", isActive:false}, {codename:"surename", isActive:false},{codename:"phoneNumber", isActive:false}]
}

if (!window.localStorage.getItem("user_selected_language"))
{
  window.localStorage.setItem("user_selected_language", config.defaultUiLanguage)
}

export const isFeatureAvailable = (name) => 
{
  if (features[name] !== null && features[name] === true)
  {
    return true
  }
  else  
  {
    return false
  }
};

export const getConfigValue = (name) => 
{
  if (config[name])
  {
    return config[name]
  }
  else if (custom[name])
  {
    return custom[name]
  }
  else  
  {
    return false
  }
};
