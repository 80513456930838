import styled from "styled-components"
import { TileVodPoster, TileLiveEvent, TileChannelSquare } from "../components/tiles"
import { Category } from "../components/listCategory"
import Stack from 'react-bootstrap/Stack';

const GridPoster = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(205px, 205px));
  gap: 12px;
  margin-bottom: 16px
`

const GridBanner = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(312px, 312px));
  gap: 12px;
  margin-bottom: 16px
`

const GridSquare = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 200px));
  gap: 12px;
  margin-bottom: 16px
`

export function GridList({list, onClickCallback})
{
	return <>
      <div key={list.objectId}>
        {list.name?<Category name={list.name} />:null}
        {list.tileType==="poster"?<GridPoster>
          {list.items.map((item) => (
            <TileVodPoster item={item} key={item.objectId?item.objectId:item.id} onClickCallback={onClickCallback} />
          ) ) }
          </GridPoster>:null}
        {list.tileType==="image"?<GridBanner>
          {list.items.map((item) => (
            <TileLiveEvent item={item} key={item.objectId?item.objectId:item.id} onClickCallback={onClickCallback} />
          ) ) }
          </GridBanner>:null}
        {list.tileType==="square"?<GridSquare>
          {list.items.map((item) => (
            <TileChannelSquare item={item} key={item.objectId?item.objectId:item.id} onClickCallback={()=>{onClickCallback(item.objectId?item.objectId:item.id)}} />
          ) ) }
          </GridSquare>:null}
      </div>
  </>
}