import Parse from "parse";
import { getPurchasedProducts, clearAllCachedListPages } from "../services/api"

export const registerUser = async( username, password, name, surename, phoneNumber ) => {
  if (username !== null && username !== "" && password !== null && password !== "")
  {

    const request_params = {
      username: username, 
      password: password, 
      email: username,
      name: name,
      surename: surename,
      phoneNumber: phoneNumber
    }
    // console.log(request_params)
    try 
    {
      const result = await Parse.Cloud.run("registerEndUser", request_params).catch(error => 
      {
           console.log(error);
      });

      if (result && result.message === "ok")
      {
        return "ok"
      }
      else  
      {
        return "error"
      }
    } catch (error) 
    {
        return "error"
    }
  }
  else  
  {
    return "no_credentials"
  }
}

export const loginUser = async( username, password, callback ) => 
{
  if (username !== null && username !== "" && password !== null && password !== "")
  {
      try 
      {
        const user_data = await Parse.User.logIn(username, password);
        await clearAllCachedListPages()
        return "ok"   
      } 
      catch (error) 
      {
        console.log(error)
        return "error"
      }
  }
  else
  {
    let user_data = await Parse.User.current();
    // console.log(user_data)
    if (user_data) // get user  via api.
    {
      // updateLocalStorage(user_data)
      if (callback) 
      { 
        callback() 
        console.log("callback") 
      }
      console.log("ok")
      return "ok"
    }
    else
    {
      console.log("no_credentials")
      return "no_credentials"
    }
  }
};

export const logoutUser = async(callback) => {
  try 
  {
    await Parse.User.logOut()
    await clearAllCachedListPages()
    if (callback)
    {
      callback()
    }
    return true
  } 
  catch (error) 
  {
  }
};

export const resetUserPassword = async( username ) => {
  if (username !== null && username !== "")
  {
    try 
    {
      const result = await Parse.User.requestPasswordReset(username)
      if (result && result.message === "ok")
      {
        return "ok"
      }
      else  
      {
        return "error"
      }
    } catch (error) 
    {
        return "error"
    }
  }
  else  
  {
    return "no_email"
  }
}


export function isUserLoggedIn()
{
    let user_data = Parse.User.current()
    if (user_data == null)
    {
      return false
    }
    else 
    {
      // updateLocalStorage(user_data)
      return true
    }
};

export function getUserObjectId()
{
    const user_data = Parse.User.current()
    if (user_data == null)
    {
      return null
    }
    else 
    {
      return user_data.id
    }
};

export function getUserLogin()
{
    const user_data = Parse.User.current()
    if (user_data == null)
    {
      return null
    }
    else 
    {
      return user_data.attributes.username
    }
};

export const refreshUserData = async() => {
  if (Parse.User.current())
  {
    Parse.User.current().fetch()
  }
};

export function getUserData()
{
  return Parse.User.current()
}

