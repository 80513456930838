import { useState, useEffect} from "react";
import { useNavigate  } from "react-router-dom"
import { NavBar } from "../components/navbar"
import { registerUser, loginUser, resetUserPassword } from "../services/user"
import { getConfigValue } from "../custom/config"
import getStr from "../data/strings";

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import styled from "styled-components"

const Card = styled.div`
  box-shadow: 0 1px 1px 0 grey;
  width: 320px;
  margin: 0 auto;
  padding: 16px;
  margin-top: 32px;
  border-radius: 16px;
  background-color: rgba(50, 50, 50, 255);
`

const CardTitle = styled.h2`
  margin-top: 4px;
  margin-bottom: 24px;
  padding: 0;
  color: white;
`

const color = {
  color: "white"
}

export default function Authenticate() 
{
  const navigate = useNavigate()  
  const [loginUi, setLoginUi] = useState(true);
  const [resetPasswordUi, setResetPasswordUi] = useState(false);

  const [warrningMessage, setWarrningMessage] = useState("");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [agreementChecked, setAgreementChecked] = useState(false);

  // registration additional fields
  const [name, setName] = useState("");
  const [nameIsActive, setNameIsActive] = useState(false);

  const [surename, setSurename] = useState("");
  const [surenameIsActive, setSurenameIsActive] = useState(false);

  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberIsActive, setPhoneNumberIsActive] = useState(false);



  async function onLoginCallback()
  {
    navigate(-1)
  }

  // SUBMIT callback
  async function onLoginSubmit(event)
  {
    event.preventDefault();
    const loginResult = await loginUser(email, password)
    if (loginResult === "ok")
    {
      onLoginCallback()
    }
    else 
    {
      setWarrningMessage("zły login lub hasło")
    }
  }

  async function onRegisterSubmit(event)
  {
    event.preventDefault();
    const registerResult = await registerUser(email, password, name, surename, phoneNumber)
    if (registerResult === "ok")
    {
      // login
      const loginResult = await loginUser(email, password)
      if (loginResult === "ok")
      {
        onLoginCallback()
      }
    }
    else  
    {
      setWarrningMessage("konto już isnieje lub jest błąd w adresie email.")
      // error , show info on UI. account already exists or try again.
    }
  }

  async function onResetPasswordSubmit(event)
  {
    event.preventDefault();
    if (email !== "" && email.search("@") !== -1)
    {
      await resetUserPassword(email)
      setEmail("")
      setWarrningMessage("Link do odzyskania hasła został wysłany na " + email + ". Sprawdź pocztę.")
    }
    else  
    {
      setWarrningMessage("Podaj prawidłowy adres email.")
    }
  }

  // on input change callback
  function onEmailChange(event)
  {
    event.preventDefault();
    setEmail(event.target.value)
  }

  function onPasswordChange(event)
  {
    event.preventDefault();
    setPassword(event.target.value)
  }

  function onTextfiledChange(event, set)
  {
    event.preventDefault();
    set(event.target.value)
  }

  function onCheckboxChanged(event)
  {
    setAgreementChecked(!agreementChecked)
  }

  // set UI status
  function showResetPasswordUi(event)
  {
    event.preventDefault();
    setResetPasswordUi(true)
    setLoginUi(false)
  }

  function showRegisterUi(event)
  {
    event.preventDefault();
    setResetPasswordUi(false)
    setLoginUi(false)
  }

  function showLoginUi(event)
  {
    event.preventDefault();
    setResetPasswordUi(false)
    setLoginUi(true)
  }

  useEffect(() => 
  {
    const registrationFields = getConfigValue("registrationFields")
    for (let i = 0; i < registrationFields.length; i++)
    {
      if (registrationFields[i].isActive === true)
      {
        if (registrationFields[i].codename === "name")
        {
          setNameIsActive(true)
        }
        else if (registrationFields[i].codename === "surename")
        {
          setSurenameIsActive(true)
        }
        else if (registrationFields[i].codename === "phoneNumber")
        {
          setPhoneNumberIsActive(true)
        }
      }
    }
  }, [])

  return (
    <>
    <NavBar/>
    {resetPasswordUi? // reset password
      <Card> 
        <CardTitle>{getStr("reset_password")}</CardTitle>
        <Form onSubmit={onResetPasswordSubmit}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label style={color}>Email</Form.Label>
            <Form.Control type="email" placeholder="email" onChange={onEmailChange} />
          </Form.Group>

          <Button variant="primary" type="submit" style={{width:"100%", marginBottom:"16px"}}>{getStr("btn_reset_password")}</Button>
          {warrningMessage!==""?<Form.Text><br/>{warrningMessage}</Form.Text>:null}
          <br/>
          <Button variant="light" onClick={showLoginUi} style={{marginTop:"12px"}}>
            {getStr("btn_login")}
          </Button>
        </Form>
      </Card>:
      <Card>
        {loginUi? // login
          <>
            <CardTitle>{getStr("login")}</CardTitle>
            <Form onSubmit={onLoginSubmit}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label style={color}>Email</Form.Label>
                <Form.Control type="email" placeholder="email" onChange={onEmailChange} />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label style={color}>{getStr("password")}</Form.Label>
                <Form.Control type="password" placeholder={getStr("password")} onChange={onPasswordChange} />
              </Form.Group>
              <Button variant="primary" type="submit" style={{width:"100%", marginBottom:"16px"}}>{getStr("btn_login")}</Button>
    
              {warrningMessage!==""?<Form.Text><br/>{warrningMessage}</Form.Text>:null}
              <Button variant="light" onClick={showRegisterUi} style={{marginTop:"12px"}}>
                {getStr("no_account")}
              </Button>
              <Button variant="light" onClick={showResetPasswordUi} style={{marginTop:"12px"}}>
                {getStr("forgot_password")}
              </Button>

            </Form>
          </>
          : // register
          <>
            <CardTitle>{getStr("register")}</CardTitle>
            <Form onSubmit={onRegisterSubmit}>
              {nameIsActive?<Form.Group className="mb-3" controlId="formBasicName">
                <Form.Label style={color}>{getStr("name")}</Form.Label>
                <Form.Control type="name" placeholder={getStr("name")} onChange={(e)=>{onTextfiledChange(e, setName)} } />
              </Form.Group>:null}

              {surenameIsActive?<Form.Group className="mb-3" controlId="formBasicSurename">
                <Form.Label style={color}>{getStr("surename")}</Form.Label>
                <Form.Control type="surename" placeholder={getStr("surename")} onChange={(e)=>{onTextfiledChange(e, setSurename)} } />
              </Form.Group>:null}

              {phoneNumberIsActive?<Form.Group className="mb-3" controlId="formBasicPhone">
                <Form.Label style={color}>{getStr("phone_number")}</Form.Label>
                <Form.Control type="phone" placeholder={getStr("phone_number")} onChange={(e)=>{onTextfiledChange(e, setPhoneNumber)} } />
              </Form.Group>:null}

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label style={color}>Email</Form.Label>
                <Form.Control type="email" placeholder="email" onChange={onEmailChange} />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label style={color}>{getStr("password")}</Form.Label>
                <Form.Control type="password" placeholder="hasło" onChange={onPasswordChange} />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicCheckbox">
                <Form.Check type="checkbox" style={color} >
                  <Form.Check.Input type="checkbox" isValid onChange={ onCheckboxChanged }/>
                  <Form.Check.Label style={color}>{getStr("accept")} <a style={color} href="/info/regulation">{getStr("terms_of_service")}</a>, <a style={color} href="/info/privacy_policy">{getStr("privacy_policy")}</a>.</Form.Check.Label>
                </Form.Check>
              </Form.Group>
              <Button variant="primary" type="submit" style={{width:"100%", marginBottom:"16px"}} disabled={!agreementChecked}>
                {getStr("btn_register")}
              </Button>
              <br/>

              {warrningMessage!==""?<Form.Text><br/>{warrningMessage}</Form.Text>:null}
              <Button variant="light" onClick={showLoginUi} style={{marginTop:"12px"}}>
                {getStr("have_an_account")}
              </Button>
            </Form>
          </>
        }
      </Card>}
    </>
  );
}