import styled from "styled-components"
import {tileCast} from "../styles/globalstyle";

const Card  = styled.div`
	box-shadow: 0 2px 2px 0 ${tileCast.shadowColor};
 	transition: 0.3s;
 	width: 128px;
 	height: 180px;
 	background-color: ${tileCast.backgroundColor};
 // 	&:hover {
	// 	cursor: pointer;
	// 	transform: scale(1.02);
	// 	transition: transform .2s;
	// };
	border-radius: ${tileCast.cornerRadius};
`

const Image = styled.div`
	width: 128px;
	height: 128px;
	background-image: ${({image}) => `url(${image});` }
	background-position: center;
	background-size: cover;
	border-radius: ${tileCast.cornerRadius};
`
const Title = styled.div`
	font-size: 14px;
	font-weight: 300;
	color: rgba(250, 250, 250, 255);	
`

const Label = styled.div`
	font-size: 14px;
	font-weight: 300;
	color: rgba(250, 250, 250, 0.8);	
`

const Container = styled.div`
	padding: 8px 0px;
`

export default function CastTile({item, label,  onClickCallback})
{
	return <Card onClick={()=>{ onClickCallback(item.objectId)}} >
		{item.image?<Image image={item.image.url}/>:null}
	  	<Container>
	  		<Label>{label}</Label>
	  		<Title>{item.name + " " + item.surename}</Title>
	 	 </Container>

  	</Card>
}