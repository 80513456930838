import styled from "styled-components"
import {tileRadius, tilePoster, tileImage} from "../styles/globalstyle";
import { timestampDateToString } from "../services/helper"

const PosterCard  = styled.div`
	box-shadow: 0 2px 2px 0 ${tilePoster.shadowColor};
 	transition: 0.3s;
 	width: 205px;
 	height: 370px;
 	background-color: ${tilePoster.backgroundColor};
 	&:hover {
		cursor: pointer;
		transform: scale(1.02);
		transition: transform .2s;
	};
	border-radius: ${tilePoster.radius};
`

const ImageCard = styled.div`
	box-shadow: 0 2px 2px 0 ${tileImage.shadowColor};
 	transition: 0.3s;
 	width: 205px;
 	height: 370px;
 	background-color: ${tileImage.backgroundColor};
 	&:hover {
		cursor: pointer;
		transform: scale(1.02);
		transition: transform .2s;
	};
	border-radius: ${tileImage.radius};
`

const ChannelCard = styled.div`
	box-shadow: 0 2px 2px 0 rgba(250, 250, 250, 0.2);;
 	transition: 0.3s;
 	width: 205px;
 	height: 370px;
 	background-color: rgba(250, 250, 250, 0.1);
 	&:hover {
		cursor: pointer;
		transform: scale(1.02);
		transition: transform .2s;
	};
	border-radius: ${tileRadius};
`


const ImagePoster = styled.div`
	width: 100%;
	height: 290px;
	background-image: ${({image}) => `url(${image});` }
	background-position: center;
	background-size: cover;
	border-radius: ${tilePoster.radius};
`

const ImageImage = styled.div`
	width: 100%;
	height: 176px;
	background-image: ${({image}) => `url(${image});` }
	background-position: center;
	background-size: cover;
	border-radius: ${tilePoster.radius};
`

const ImageChannel = styled.div`
	width: 140px;
	height: 140px;
	background-image: ${({image}) => `url(${image});` }
	background-position: center;
	background-size: cover;
	margin-top: 20px;
	margin-left: 30px;
	border-radius: ${tilePoster.radius};
`

const Title = styled.div`
	font-size: 18px;
    font-weight: 400;
    color: rgba(250, 250, 250, 255);	
`
const Label = styled.div`
	font-size: 16px;
    font-weight: 400;
    color: red;
`	

const ContainerPoster = styled.div`
	padding: ${tilePoster.padding};
`
const ContainerImage = styled.div`
	padding: ${tileImage.padding};
`

export function TileVodPoster({item, onClickCallback})
{
	return <PosterCard onClick={()=>{ onClickCallback(item.objectId)}} >
		<ImagePoster image={item.image.url}/>
	  	<ContainerPoster>
	  		<Title>{item.title}</Title>
	 	 </ContainerPoster>

  	</PosterCard>
}

export function TileLiveEvent({item, onClickCallback})
{
	if (item.mainMediaFileStartPublishDate)
    {
      item.label =  "Start: "
      item.label = item.label + timestampDateToString(item.mainMediaFileStartPublishDate)
    }
	return <ImageCard onClick={()=>{ onClickCallback(item.objectId)}} style = {{ height: "250px", width: "312px"}}>
		<ImageImage image={item.image.url} />
	  	<ContainerImage>
	  		<Title >{item.title}</Title>
	  		{item.label?<Label>{item.label}</Label>:null}
	 	 </ContainerImage>

  	</ImageCard>
}

export function TileChannelSquare({item, onClickCallback})
{
	return <ChannelCard onClick={onClickCallback} style = {{ height: "200px", width: "200px"}}>
		<ImageChannel image={item.image.url} />
		<Title style={{textAlign:"center", marginTop: "6px"}}>{item.name}</Title>
  </ChannelCard>
}