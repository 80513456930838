import { useState, useEffect} from "react";
import { useNavigate  } from "react-router-dom"
import { NavBar } from "../components/navbar"
import { getProducts, buyProduct } from "../services/api"
import { isUserLoggedIn } from "../services/user"
import { Loader } from "../components/loader"
import getStr from "../data/strings";

// import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import styled from "styled-components"

const Container = styled.div`
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
`

export default function Products() 
{
  const navigate = useNavigate()  
  const [products, setProducts] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  async function getProductsData()
  {
    setIsLoading(true)
    const products_data = await getProducts("svod")
    if (products_data)
    {
      setProducts(products_data)
    }
    setIsLoading(false) 
  }

  async function onClickBuyProduct(e, productObjectId)
  {
    e.preventDefault()
    if (isUserLoggedIn())
    {
      setIsLoading(true)
      await buyProduct(productObjectId)
      setIsLoading(false)
    }
    else  
    {
      navigate("/page/authenticate")
    }
  }

  useEffect(() => 
  {
    getProductsData()
  }, [])

  return (
    <>
      <NavBar/>
        
        <Row className="justify-content-md-center">
          <Col sm lg="2">
          </Col>
          <Col sm md="auto">
            <h1 style={{textAlign:"center", marginTop:"56px", marginBottom:"56px"}}>{getStr("available_products")}</h1>
            {isLoading?<Loader marginTop={"0px"}/>:null}
            {products.map((product) => (
              <Card key={product.objectId} className="text-center" style = {{margin: "16px", padding: "16px" }} bg="dark" text="white">
                  <Card.Body >
                    <Card.Title>{product.name}</Card.Title>
                    <Card.Text style = {{color: "white"}}>
                      {product.description}
                    </Card.Text>
                    <Button disabled={isLoading} onClick={(e)=>{onClickBuyProduct(e, product.objectId)}} >{getStr("buy")} | {product.price} {product.currency}</Button>
                  </Card.Body>
              </Card>
            ))}
          </Col>
          <Col sm lg="2">
          </Col>
        </Row>
      
    </>
  );
}
