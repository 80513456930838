import Parse from 'parse';
import { useState, useEffect} from "react";
import { useNavigate, useParams  } from "react-router-dom"
import { getConfig } from "../services/config"
import { NavBar } from "../components/navbar"
import { Footer } from "../components/footer"
import { GridList } from "../components/grid"
import { getChannelsPage, getMainMediafileData, getProducts } from "../services/api"
import Container from 'react-bootstrap/Container';
import { Player } from "../components/player"
import { isUserLoggedIn } from "../services/user"
import { Loader } from "../components/loader"
import { Category } from "../components/listCategory"
import { PurchaseDialog } from "../components/purchaseDialog"
import getStr from "../data/strings";

export default function ListsPage() 
{
  const { page_id } = useParams();
  const navigate = useNavigate();
  const [openPurchaseDialog, setOpenPurchaseDialog] = useState(false);
  const [products, setProducts] = useState([])
  const [channels, setChannels] = useState([])
  const [showPlayer, setShowPlayer] = useState(false)
  const [mainMediaFile, setMainMediaFile] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [selectedChannelObjectId, setSelectedChannelObjectId] = useState("")


  async function init()
  {
    setIsLoading(true)
    const result = await getChannelsPage()
    console.log(result)
    setIsLoading(false)
    if (!result.isError)
    {
      setChannels(result.channels) 
    }
    else 
    {
      navigate("/page/authenticate")
    }
  }

  async function playMainMediaFile(channelObjectId)
  {
    if (isUserLoggedIn())
    {
      setSelectedChannelObjectId(channelObjectId)
      setShowPlayer(false)
      const stream_data = await getMainMediafileData(channelObjectId, "channel")
      console.log(stream_data)
      if (stream_data.codename == "ok")
      {
        setShowPlayer(true)
        setMainMediaFile({url:stream_data.url})
      }
      else if (stream_data.codename == "no_manifest") // no manifest
      {
 
      }
      else if (stream_data.codename == "error_user_blocked") // no manifest
      {
        alert(getStr("warrning_user_is_blocked"))
      }
      else if (stream_data.codename == "too_many_sessions") // too many session. show info to user
      {
        alert(getStr("warrning_too_many_devices"))
      }
      else if (stream_data.codename == "ip_not_allowed") // too many session. show info to user
      {
        alert(getStr("warrning_video_not_available_in_your_location"))
      }
      else if (stream_data.codename == "product_required")
      {
        setOpenPurchaseDialog(true)
        getProductsData(stream_data.product_required)
      }
    }
    else 
    {
      navigate("/page/authenticate")
    }
  }

  async function getProductsData(product_required)
  {
      const products_data = await getProducts(null, product_required)
    console.log(products_data)
      if (products_data && products_data.length > 0)
      {
        setProducts(products_data)
      }
      else
      {
        alert("kanał jest niedostepny w tej chwili.")
        setOpenPurchaseDialog(false)
      }
  }

  function purchaseDialogCallback(codename)
  {
    if (codename === "ip_not_allowed")
    {
      alert("Produkt nie jest dostepny w twojej lokalizacji")
    }
  }

  useEffect(() => 
  {
    init()
  }, [])

  return (
    <>
      <NavBar/>
      <Container style={{minHeight:"960px", marginTop:"16px"}}>
        <PurchaseDialog openDialog={openPurchaseDialog} setOpenDialog={setOpenPurchaseDialog} products={products} assetObjectId={selectedChannelObjectId} callback={purchaseDialogCallback} />
        {showPlayer===true?<Player mainMediaFile={mainMediaFile}/>:null}
        <Category name={getStr("channels_list")} />
        {isLoading?<Loader/>:null}
        <GridList list={channels} onClickCallback={playMainMediaFile}/>
      </Container>
      <Footer/>
    </>
  );
}