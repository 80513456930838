import React from 'react';
import { useState, useEffect} from "react";
import { useNavigate  } from "react-router-dom"
import { getMenuConfig, getConfig } from "../services/config"
import { isFeatureAvailable } from "../custom/config"
import { isUserLoggedIn } from "../services/user"
import getStr from "../data/strings";

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Form from 'react-bootstrap/Form';

export function NavBar({callback, hideSearch}) {
  const [menuItems, setMenuItems] = useState([])
  const [icon, setIcon] = useState("")
  const navigate = useNavigate();

  function goTo(path, codename)
  {
    navigate("/"+path+"/"+codename)
    if (callback)
    {
      callback(codename)
    }
  }

  function onBackClicked()
  {
    navigate(-1)
  }

  async function setItems()
  {
    const config = await getConfig()
    const _menu_config = config.get("mainMenu")

    setIcon("../custom_images/logo_navbar.png")
    let _menu_items = []
    if (_menu_config)
    { 
      for (let i = 0; i < _menu_config.length; i++)
      {
        if (_menu_config[i].visibility === "always")
        {
          _menu_items.push(_menu_config[i])
        }
        else if (_menu_config[i].visibility === "authenticated")
        {
          if (isUserLoggedIn())
          {
            _menu_items.push(_menu_config[i])
          }
        }
        else if (_menu_config[i].visibility === "unauthenticated")
        {
          if (!isUserLoggedIn())
          {
            _menu_items.push(_menu_config[i])
          }
        }
      }
    }
 
    setMenuItems(_menu_items)
  }

  function onSearchClick()
  {
    navigate("/search")
  }

  useEffect(() => 
  {
    setItems()
  }, [])

  return (
    <>
      <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" sticky="top">
        <Container>
          <Navbar.Brand >
            <img
              src="../custom_images/logo_navbar.png"
              width="60"
              height="30"
              className="d-inline-block align-top"
              alt="logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              {menuItems.map((item) => (
                  <Nav.Link key={item.codename} onClick={(e)=>{ goTo(item.whatType, item.codename) }}>
                    {item.name}
                  </Nav.Link>
                ) ) }              
            </Nav>
            {hideSearch?null:<Form.Control type="text" placeholder={getStr("search")} style={{maxWidth: 240, backgroundColor:"black", borderColor:"black"}} onClick={onSearchClick}/>}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}