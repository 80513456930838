import { useState, useEffect} from "react";
import { buyProduct } from "../services/api"
import { Loader } from "../components/loader"
import getStr from "../data/strings";

import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

export function PurchaseDialog({openDialog, setOpenDialog, products, assetObjectId, callback})
{

	const [isLoading, setIsLoading] = useState(false)

	function closeDialog()
	{
		setOpenDialog(false)
	}

	async function onClickBuyProduct(e, productObjectId)
	{
	    e.preventDefault()
	    setIsLoading(true)
	    const result = await buyProduct(productObjectId, assetObjectId)
	    setIsLoading(false)
	    if (result.codename === "ip_not_allowed")
	    {
	    	if (callback)
	    	{
	    		callback(result)
	    	}
	    }
	}

	useEffect(() => 
	{

	}, [])

	return openDialog?<div style={{marginBottom: "90px"}}>
			 <h1>{getStr("gain_access")}</h1>
			 {products && products.length>0?null:<Loader marginTop={"0px"}/>}
        {products.map((product) => (
          <Card key={product.objectId} className="text-center" style = {{margin: "32px 0 16px 0", maxWidth: "480px"}} bg="dark" text="white">
              <Card.Body >
                <Card.Title>{product.name}</Card.Title>
                <Card.Text style = {{color: "white"}}>
                  {product.description}
                </Card.Text>
                <Button disabled={isLoading} onClick={(e)=>{onClickBuyProduct(e, product.objectId)}} >{getStr("buy")} | {product.price} {product.currency}</Button>
              </Card.Body>
          </Card>
        ))}

     
	  <Button variant="light" onClick={closeDialog}>{getStr("btn_close")}</Button>
  </div>:null
}