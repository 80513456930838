const str = {

  word_related: "Related",


  list_category_show_more: "show more",
  search: "search",
  search_field_info: "type in title or name",
  search_no_results_info: "no results for phrase '",
  
  password: "Password",

  login: "Login",
  btn_login: "Login",
  no_account: "Don't have an account? Register.",
  forgot_password: "Forgot password?",
  register: "Register",
  btn_register: "Register",
  have_an_account: "have an account? Login.",
  accept: "Accept",
  terms_of_service: "terms of service",
  privacy_policy: "privacy policy",
  reset_password: "Reset password",
  btn_reset_password: "Reset",
  name: "Name",
  surename: "Surename",
  phone_number: "Phone number",
  available_products:"Available products",
  buy: "buy",
  channels_list: "Channels",
  category_all: "All",
  btn_play: "Play",
  btn_play_trailer: "Play trailer",
  btn_buy_access:"buy access",
  user_has_access: "you already bought access.",
  gain_access: "Gain access",
  btn_close: "close",
  page_paymenterror_info_1: "Problem with your payment",
  page_paymenterror_info_2: "please try again or check FAQ for more information.",

  page_thankyou_info_title: "thank you for purchase",
  page_thankyou_info_payment_processing: "we are checking you payment status, please wait.",
  page_thankyou_info_payment_completed: "payment is completed",

  page_myaccount_myproducts: "My products: ",
  page_myaccount_youhaveaccess: "Have access to: ",
  page_myaccount_mylogin: "My login: ",

  page_asset_available_from: "Available from: ",
  page_asset_start: "Start: ",
  page_asset_not_available_anymore: "Available ended: ",
  page_asset_ended: "Ended: ",

  footer_about_us: "About us",
  footer_about_contact: "Contact",
  footer_about_terms_and_conditions: "Terms and conditions",
  footer_about_privacy_policy: "Privacy policy",

  btn_go: "go",
  btn_logout: "logout",

  warrning_user_is_blocked: "User is blocked. Please contact support for more information.",
  warrning_too_many_devices: "you are using too many devices. Limit resets every 24h.",
  warrning_video_not_available_in_your_location: "Video is not available in your location.",

  word_cast: "Cast",

  // cast
  director: "Director",
  actor: "Actor",
  writer: "Writer",
  
  // // menu
  // home: "Home",
  // live: "Live events",
  // vod: "VOD",
  // channelsgrid: "Live Channels",
  // hGR6DzFQD9: "VOD catalogue",
  // products: "Products",
  // myaccount: "My account",
  // authenticate: "Authenticate",
}

export default function returnStrEN(key) 
{
  return str[key]
};
