import React, { useRef, useEffect } from 'react'
import ShakaPlayer from 'shaka-player-react';
import 'shaka-player/dist/controls.css';
import shaka from 'shaka-player';
import { updateWatchSession } from "../services/api"
import { isFeatureAvailable } from "../custom/config"
import { isSafari } from "../services/helper"

export function Player({mainMediaFile, widevineLicenseServer, playreadyLicenseServer, fairplayCertificateUrl, setDebug})
{
	const controllerRef = useRef(null);
	let timerRef

	function initApp() 
	{
	  // Install built-in polyfills to patch browser incompatibilities.
	  shaka.polyfill.installAll();

	  // Check to see if the browser supports the basic APIs Shaka needs.
	  if (shaka.Player.isBrowserSupported()) {
	    // Everything looks good!
	    initPlayer();
	  } else {
	    // This browser does not have the minimum set of APIs we need.
	    console.error('Browser not supported!');
	  }
	}

	async function initPlayer() {
	  // Create a Player instance.
	    
  		const video = document.getElementById('video');
  		window.video = video;
		const player = new shaka.Player(video);
		window.player = player;
		player.addEventListener('error', onErrorEvent);
		player.addEventListener('BufferingEvent', (e)=>{console.log(e)});

	  	if (mainMediaFile.drmProtected) // DRM support
	  	{
		 	
	  		let servers = {}
		  	if (mainMediaFile.drmType == "widevine")
		  	{
		  		servers["com.widevine.alpha"] = mainMediaFile.licenseServerUrl
		  		player.configure({
					drm: {
						servers: servers
					}
				});
				
				  // // Listen for error events.
				

				  // // Try to load a manifest.
				  // // This is an asynchronous process.
				try {
				    player.load(mainMediaFile.url).then(onLoadSuccess);
				} catch (e) {
				    // onError is executed if the asynchronous load fails.
				    onError(e);
				}
		  	}
		    else if (mainMediaFile.drmType == "fairplay")
		    {
				let str = ""
				let licenseUri = ""
				let contentId = ""

	            player.getNetworkingEngine().registerRequestFilter(requestFilter);
	            player.getNetworkingEngine().registerResponseFilter(responseFilter);

	            let playerOptions = {
	                drm: {
	                    fairPlayTransform: false,
	                    advanced: {
	                        'com.apple.fps.1_0': {
	                            serverCertificateUri: mainMediaFile.fairplayCertificateUrl
	                        }
	                    },
	                    servers: {
					      // YOUR LICENSE SERVER GOES HERE:
					      'com.apple.fps.1_0': mainMediaFile.licenseServerUrl
					    },
	                    initDataTransform: initDataTransform
	                }
	            }
	      
	            player.configure(playerOptions);

	            player.load(mainMediaFile.url)
	                .then(onLoadSuccess)
	                .catch(showError);

	            function responseFilter(type, response) {
		            if (type !== shaka.net.NetworkingEngine.RequestType.LICENSE) {
		                return;
		            }
		        }

		        function requestFilter(type, request) {
		
		            if (type !== shaka.net.NetworkingEngine.RequestType.LICENSE) {
		                return;
		            }

				    request.headers['Content-Type'] = 'application/json';
				    request.headers['X-Drm-BrandGuid'] = mainMediaFile.brandGuid
					request.headers['X-Drm-UserToken'] = mainMediaFile.userToken
				    request.uris = [licenseUri];
		        }

		        function initDataTransform(initData, initDataType) {
		            let skdUrl = shaka.util.StringUtils.fromBytesAutoDetect(initData);
    				licenseUri = skdUrl.replace('skd://', 'https://');
    	
		            contentId = shaka.util.FairPlayUtils.defaultGetContentId(initData);
		            const cert = player.drmInfo().serverCertificate;
		            return shaka.util.FairPlayUtils.initDataTransform(initData, contentId, cert);
		        }


		        function showErrorEventHandler(event) {
		            showError(event.detail);
		        }

		        function showError(error) {
		            let message = 'see console';
		            if (error.message && error.message.match) {
		                let matches = error.message.match(/[^Shaka Error].+?(?=\()/);
		                if (matches) {
		                    message = matches[0].replace(/\./g, ' ').replace(/_/g, ' ');
		                }
		            }
		        }
			}
        }
		else  
		{

			try {
				// console.log(mainMediaFile)
			    player.load(mainMediaFile.url).then(onLoadSuccess);
			} catch (e) {
			    // onError is executed if the asynchronous load fails.
			    onError(e);
			}
		}

	  	//getStats()
	  	// player.currentTime
	  	// player.seekRange().end // lenght of video
	  	// get stats every 10% of asset lenght min. update watch session
	  	async function checkPlayerState()
	  	{
	  		const data = player.getStats()
	  		const currentWatchSessionObjectId = window.localStorage.getItem("currentWatchSessionObjectId")  
	  		if (currentWatchSessionObjectId)
	  		{
	  			//
	  			let bufferingCount = 0
	  			for (let i = 0; i < data.stateHistory.length; i++)
	  			{
	  				if (data.stateHistory[i].state === "buffering")
	  				{
	  					bufferingCount = bufferingCount + 1
	  				}
	  			}
		  		const result = await updateWatchSession({
		  			watchSessionObjectId: currentWatchSessionObjectId,
		  			watchDuration: data.playTime,
		  			bufferingDuration: data.bufferingTime,
		  			bufferingCount: bufferingCount
		  		})
		  		// console.log(data)
	  		}
	  	}

		function onLoadSuccess() 
		{
		   	video.play()
		   	if (isFeatureAvailable("videoStatistics") && !isSafari()) // iOS and mac can go into native player mode and timers does not work.
		   	{
			   	let updateInterwal = Math.round( player.seekRange().end/10 ) // 10% of material lenght. not more than 1 every minute
			   	if (updateInterwal < 60)
			   	{
			   		updateInterwal = 60
			   	}
			   	if (updateInterwal > 1200)
			   	{
			   		updateInterwal = 1200
			   	}
			   	timerRef = setInterval(checkPlayerState, updateInterwal*1000)// change to milliseconds
		   	}
		}
	}

	function onErrorEvent(event) {
	  // Extract the shaka.util.Error object from the event.
	  onError(event.detail);
	  	const currentWatchSessionObjectId = window.localStorage.getItem("currentWatchSessionObjectId")  
  		if (currentWatchSessionObjectId)
  		{
	  		updateWatchSession({
	  			watchSessionObjectId: currentWatchSessionObjectId,
	  			errorOccured: true,
	  		})
  		}
	}

	function onError(error) {
	  // Log the error.
	  console.error('Error code', error.code, 'object', error);
	}

 	useEffect(() => 
 	{
 		initApp()

 		return () => {
             window.removeEventListener('error', onErrorEvent);
             clearTimeout(timerRef);
           }

	}, []);

	return <>
		<video id="video" style={{ backgroundSize: "cover", width: "100%", maxHeight: "960px", paddingBottom: "80px", paddingTop: "20px", display: "block", margin: "0 auto" }}
           // poster="//shaka-player-demo.appspot.com/assets/poster.jpg"
           controls ></video>
	</>
}