import Parse from 'parse';
import styled from "styled-components"
import { useState, useEffect} from "react";
import { useNavigate, useParams  } from "react-router-dom"
import { getConfig } from "../services/config"
import { NavBar } from "../components/navbar"
import { Footer } from "../components/footer"
import { GridList } from "../components/grid"
import { getSingleListPage } from "../services/api"
import Container from 'react-bootstrap/Container';
import { Loader } from "../components/loader"
import { scrollToTop } from "../services/helper"

const Banner = styled.img`
  background-size: cover;
  position: relative; 
  outline: none; 
  background: none; 
  background-color: transparent; 
  border: none; 
  border: 0px; 
  width: 100%; 
  max-height: 640px; 
  object-fit: cover; 
  opacity: 1;
`

export default function ListsPage() 
{
  const { page_id } = useParams();
  const navigate = useNavigate();
  const [list, setList] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  async function init(listObjectId)
  {
    setIsLoading(true)
    const list_data = await getSingleListPage(listObjectId)
    if (!list_data.isError)
    {
      setIsLoading(false)
      setList(list_data)  
    }
    else 
    {
      navigate("/page/authenticate")
    }
  }

  function setScreen(listObjectId)
  {
    init(listObjectId)
  }

  function goToAsset(object_id)
  {
    navigate("/asset/"+object_id)
  }

  useEffect(() => 
  {
    if (page_id)
    {
      scrollToTop()
      setScreen(page_id)
    }

  }, [])

  return (
    <>
      <NavBar/>
      <Container style={{minHeight:"960px", marginTop:"16px"}}>
        {isLoading?<Loader/>:null}
        {list.image?<Banner src={list.image.url} alt={list.name} />:null}
        <GridList list={list} onClickCallback={goToAsset} />
      </Container>
      <Footer/>
    </>
  );
}