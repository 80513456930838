import styled from "styled-components"
import { useState, useEffect} from "react";
import { useNavigate, useParams  } from "react-router-dom"
import { getConfigValue } from "../custom/config"
import { timestampDateToString } from "../services/helper"
import { NavBar } from "../components/navbar"
import { Footer } from "../components/footer"
import { Player } from "../components/player"
import { getMainMediafileData, getSubMediafileData, getAssetPage, getProducts } from "../services/api"
import { showAssetButton } from "../services/video"
import { PurchaseDialog } from "../components/purchaseDialog"
import getStr from "../data/strings";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import { Loader } from "../components/loader"
import { GridList } from "../components/grid"
import { GridPeople } from "../components/gridPeople"
import Alert from 'react-bootstrap/Alert';

const Banner = styled.img`
  background-size: cover;
  position: relative; 
  outline: none; 
  background: none; 
  background-color: transparent; 
  border: none; 
  border: 0px; 
  width: 100%; 
  max-height: 640px; 
  object-fit: cover; 
  opacity: 1;
`

const AssetTitle = styled.div`
    font-size: 38px;
    font-weight: 600;;
    color: rgba(255, 255, 255, 255);  
`

const AssetText = styled.div`
    font-size: 18px;
    font-weight: 400;;
    color: rgba(255, 255, 255, 255);  
`

const generalStyle = {
  marginTop: "12px"
}

export default function Asset() 
{
  const { asset_id } = useParams();
  const navigate = useNavigate();

  const [openPurchaseDialog, setOpenPurchaseDialog] = useState(false);

  const [isLoading, setIsLoading] = useState(false)
  const [asset, setAsset] = useState({})

  const [assetObjectId, setAssetObjectId] = useState("")
  const [showPlayer, setShowPlayer] = useState(false)
  const [mainMediaFile, setMainMediaFile] = useState({})

  const [products, setProducts] = useState([])
  const [productObjectId, setProductObjectId] = useState([])

  const [mainMediaFileButton, setMainMediaFileButton] = useState("")

  const [warrningMessage, setWarrningMessage] = useState("")

  const [castRoles, setCastRoles] = useState([])

  const [refreshTimer, setRefreshTimer] = useState()


  function mainfestPublishDateCheck(asset_data)
  {
    if ( (Date.now()/1000) >  asset_data.mainMediaFileStartPublishDate)
    {
      clearInterval(refreshTimer)
      refreshPage()
    }
  }

  async function setPage(assetObjectId)
  {
      setIsLoading(true)
      const asset_data = await getAssetPage(assetObjectId)
      // console.log(asset_data)
      setProductObjectId(asset_data.productObjectId)

      // set info
      if (asset_data.mainMediaFileStartPublishDate)
      {
        asset_data.label_important = ""
        if (asset_data.whatType === "vod")
        {
          asset_data.label_important = getStr("page_asset_available_from")
        }
        else if (asset_data.whatType === "live")
        {
          asset_data.label_important = getStr("page_asset_start")
        }
        asset_data.label_important = asset_data.label_important + timestampDateToString(asset_data.mainMediaFileStartPublishDate)

        setRefreshTimer( setInterval(()=>{mainfestPublishDateCheck(asset_data)}, 1000*10) )
      }
      else  
      {
        clearInterval(refreshTimer)
      }
      if (asset_data.mainMediaFileStopPublishDate)
      {
        asset_data.label_info = ""
        if (asset_data.whatType === "vod")
        {
          asset_data.label_info = getStr("page_asset_not_available_anymore")
        }
        else if (asset_data.whatType === "live")
        {
          asset_data.label_info = getStr("page_asset_ended")
        }
        asset_data.label_info = asset_data.label_info + timestampDateToString(asset_data.mainMediaFileStopPublishDate)
      }
      setAsset(asset_data)
      const assetButtonType = await showAssetButton(asset_data.productObjectId, asset_data.objectId, asset_data.isMainMediafileAvailable, asset_data.canPurchase)
      setMainMediaFileButton(assetButtonType)

      setIsLoading(false)
  }

  async function playMainMediaFile()
  {
      setShowPlayer(false)
      const stream_data = await getMainMediafileData(assetObjectId)

      if (stream_data.codename === "ok")
      {
        setShowPlayer(true)
        setMainMediaFile(stream_data)
      }
      else if (stream_data.codename == "error_user_blocked") // no manifest
      {
        alert(getStr("warrning_user_is_blocked"))
      }
      else if (stream_data.codename === "no_manifest") // no manifest
      {
 
      }
      else if (stream_data.codename === "too_many_sessions") // too many session. show info to user
      {
        setWarrningMessage(getStr("warrning_too_many_devices"))
      }
      else if (stream_data.codename === "ip_not_allowed") // too many session. show info to user
      {
        setWarrningMessage(getStr("warrning_video_not_available_in_your_location"))
      }
  }

  async function playSubMediaFile()
  {
      setShowPlayer(false)
      const stream_data = await getSubMediafileData(assetObjectId)
      console.log(stream_data)
      if (stream_data.codename === "ok")
      {
        setShowPlayer(true)
        setMainMediaFile({url:stream_data.url})
      }
      else if (stream_data.codename === "no_manifest") // no manifest
      {
 
      }
      else if (stream_data.codename === "too_many_sessions") // too many session. show info to user
      {
        setWarrningMessage("używasz zbyt wielu urządzeń. usuń sesje oglądania na innych urządzeniach lub wróć później.")
      }
      else if (stream_data.codename === "ip_not_allowed") // too many session. show info to user
      {
        setWarrningMessage("Materiał nie jest dostepny w twojej lokalizacji")
      }
  }

  async function getProductsData()
  {
      const products_data = await getProducts(null, productObjectId)
      if (products_data)
      {
        setProducts(products_data)
      }
  }

  function purchase()
  {
    setOpenPurchaseDialog(true)
    getProductsData()
  }

  function purchaseDialogCallback(codename)
  {
    if (codename === "ip_not_allowed")
    {
      setWarrningMessage("Produkt nie jest dostepny w twojej lokalizacji")
    }
  }

  function login()
  {
    navigate("/page/authenticate")
  }

  function goToAsset(object_id)
  {
    navigate("/asset/"+object_id)
    refreshPage()
  }

  function refreshPage()
  {
    window.location.reload(false);
  }

  useEffect(() => 
  {
    if (asset_id)
    {
      let _castRole = getConfigValue("castRole") // update cast setting with proper translated strings
      for (let i = 0; i < _castRole.length; i++)
      {
        _castRole[i].name = getStr(_castRole[i].codename)
      }
      setCastRoles(_castRole)

      setPage(asset_id)
      setAssetObjectId(asset_id)   
    }

    window.addEventListener('popstate', refreshPage, false);
    return () => {
      clearInterval(refreshTimer)
      window.removeEventListener('popstate', refreshPage)
    }
  }, [])

  return (
    <>
      <NavBar/>
      {warrningMessage!==""?<p>{warrningMessage}</p>:null}
      {showPlayer===true?<Player mainMediaFile={mainMediaFile} setDebug={setWarrningMessage}/>:<>{asset.image?<><Banner src={asset.image.url} alt={asset.title} /></>:<Banner src="../images/banner_placeholder.png" alt="placeholder" />}</>}
    
      {isLoading?<Loader/>:null}
      <Container>
        <PurchaseDialog openDialog={openPurchaseDialog} setOpenDialog={setOpenPurchaseDialog} products={products} assetObjectId={asset.objectId} callback={purchaseDialogCallback} />
        <Row style={{marginTop: "16px"}}>
          <Col sm>
            <AssetTitle>
              {asset.title} 
            </AssetTitle>
          </Col>
        </Row>
        {asset.label_important?<Row style={generalStyle}><Col><AssetText style={{color:"red"}}>{asset.label_important}</AssetText></Col></Row>:null}
        {asset.label_info?<Row style={generalStyle}><Col><AssetText style={{color:"orange"}}>{asset.label_info}</AssetText></Col></Row>:null}

        <Row style={generalStyle}>
          <Col sm>
            <AssetText style={{opacity: "0.5"}}>
              <Stack direction="horizontal" gap={3}>
                {asset.year>0?<div>{asset.year}</div>:null}
                {asset.duration?<div>{asset.duration} min</div>:null}
                {asset.ageRating&&asset.ageRating!=""?<img src={`../custom_images/ageRating/${asset.ageRating}.png`} alt={asset.ageRating} width="24" height="24" />:null}
              </Stack>
            </AssetText>
          </Col>
        </Row>
        <Row>
          <Col sm>
             <Stack direction="horizontal" gap={3} style={generalStyle}>
                {mainMediaFileButton==="login"?<Button onClick={login}>{getStr("btn_login")}</Button>:null}
                {mainMediaFileButton==="buy"?<Button onClick={purchase}>{getStr("btn_buy_access")}</Button>:null}
                {mainMediaFileButton==="play"?<Button onClick={playMainMediaFile}>{getStr("btn_play")}</Button>:null}  
                {mainMediaFileButton==="has_access"?<Alert variant="success">{getStr("user_has_access")}</Alert >:null}
                {asset.isSubMediafileAvailable?<Button variant="light" onClick={playSubMediaFile}>{getStr("btn_play_trailer")}</Button>:null}
            </Stack>
            <AssetText style={generalStyle}>
              {asset.description}
            </AssetText>

          </Col>
        </Row>
        
        <AssetText style={generalStyle}>
          {asset.cast&&asset.cast.isActive === true?<>
            <Row style = {{marginTop: "32px"}}>
              <h3>{getStr("word_cast")}</h3> 
            </Row>
            <GridPeople castRoles={castRoles} cast={asset.cast} />
          </>:null}
        </AssetText>

        {asset.relatedAssets&&asset.relatedAssets.items.length>0?<>
          <Row style = {{marginTop: "32px"}}>
            <h3>{getStr("word_related")}</h3> 
          </Row>
          <Row>
            <GridList list={asset.relatedAssets} onClickCallback={goToAsset} />
          </Row>
          </>:null}

      </Container>

      <Footer/>
    </>
  );
}