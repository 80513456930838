import styled from "styled-components"
import { Category } from "../components/listCategory"
import CastTile from "../components/castTile"

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(128px, 128px));
  gap: 12px;
  margin-bottom: 16px
`

export function GridPeople({castRoles, cast, category})
{
	return <Grid>
    {castRoles.map( (item) => (
      <>
        {cast[item.codename]?
        <>
          {cast[item.codename].map( (cast_item) => ( 
            <CastTile key={item.objectId} item={cast_item} label={item.name} />
          ) )}
        </>:null}
      </>
    ))}
  </Grid>
}