import Parse from 'parse';
import { useState, useEffect} from "react";
import { useNavigate, useParams  } from "react-router-dom"
import { getConfig } from "../services/config"
import { NavBar } from "../components/navbar"
import { Footer } from "../components/footer"

import { Lists } from "../components/lists"
import { Loader } from "../components/loader"
import { getListsPage } from "../services/api"

import Container from 'react-bootstrap/Container';


export default function ListsPage() 
{
  const { page_id } = useParams();
  const navigate = useNavigate();
  const [lists, setLists] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  async function init(setLists, page_codename)
  {
    setIsLoading(true)
    setLists([])
    const list_data = await getListsPage(page_codename)

    setIsLoading(false)  
    if (!list_data.isError)
    {
      setLists(list_data)  
    }
    else 
    {
      navigate("/page/authenticate")
    }

  }

  function setScreen(codename)
  {
    init(setLists, codename)
  }

  function goToAsset(object_id)
  {
    navigate("/asset/"+object_id)
  }

  function goToList(object_id)
  {
    navigate("/list/"+object_id)
  }

  useEffect(() => 
  {
    if (page_id)
    {
      setScreen(page_id)
    }

  }, [])

  return (
    <>
      <NavBar callback={setScreen}/>
        {isLoading?<Loader/>:null}
        <Lists lists={lists} onTileClickCallback={goToAsset} goToList={goToList}/>
      <Footer/>
    </>
  );
}