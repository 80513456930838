
export const timestampDateToString = (timestamp) => 
{
  const today = new Date()
  let a = new Date(timestamp*1000);
  let time = ""
  if (today.getDate() !== a.getDate())
  {
    time = time + a.getDate() + " / " + (a.getMonth()+1) + " / " + a.getFullYear() + " | "
  }

  var minutes = "0" + a.getMinutes()
  time = time + a.getHours() + ":" + minutes.substr(-2);

  return time;
};

export const isSafari = () => 
{
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}


export const scrollToTop = () =>
{
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0;
}