
export const color_primary = "#8363B7"
export const color_accent = "#FFAB1A"

export const tileRadius = "8px"
export const category_font_size = "18px"

export const main_font = "'Open Sans', sans-serif;"

export const deployment_type = "prod"

export const listCategory = {
	color: "#FFFFFF",
	fontSize: "32px",
}
export const listCategoryMore = {
	color: "#FFFFFF",
	fontSize: "18px",
}

export const tilePoster = {
	backgroundColor: "#FFFFFFF",
	shadowColor: "#FFFFFFF",
	background: false,
	shadow: false,
	width: "144px",
	height: "204px",
	textSpace: "36px",
	padding: "6px 0px",
	radius: "8px",
	title: {
		fontSize: "18px",
		// font: ui.fonts.medium,
		color: "white",
		// rows: 1,
		marginLeft: "0px",
		isActive: true
	}
}

export const tileImage = {
	backgroundColor: "#FFFFFFF",
	shadowColor: "#FFFFFFF",
	background: false,
	shadow: false,
	width: "144px",
	height: "204px",
	textSpace: "36px",
	padding: "6px 0px",
	radius: "8px",
	title: {
		fontSize: "18px",
		// font: ui.fonts.medium,
		color: "white",
		// rows: 1,
		marginLeft: "0px",
		isActive: true
	}
}

export const tileCast = {
	backgroundColor: "#FFFFFFF",
	shadowColor: "#FFFFFFF",
	background: false,
	shadow: false,
	width: "144px",
	height: "204px",
	textSpace: "36px",
	cornerRadius: "8px",
	title: {
		fontSize: "18px",
		// font: ui.fonts.medium,
		color: "white",
		// rows: 1,
		marginLeft: "0px",
		isActive: true
	}
}