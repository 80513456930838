import React from 'react';
import { useState, useEffect} from "react";
import { useNavigate  } from "react-router-dom"
import { isFeatureAvailable, getConfigValue } from "../custom/config"
import getStr from "../data/strings";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';

export function Footer({onStaticPageClickCallback}) {
  const [menuItems, setMenuItems] = useState([])
  const navigate = useNavigate();

  function goTo(codename)
  {
    navigate("/info/"+codename)
    if (onStaticPageClickCallback)
    {
      onStaticPageClickCallback()
    }
  }

  function onAppStoreClicked()
  {
    window.open(getConfigValue("appStoreUrl"), '_blank');
  }

  function onGooglePlayClicked()
  {
    window.open(getConfigValue("googlePlayUrl"), '_blank');
  }


  useEffect(() => 
  {
    
  }, [])

  return (
    <Container style={{height:"128px", marginTop: "64px", marginBottom: "64px"}}>
      <Row>
        <Col sm>
           <Stack gap={1}>
             <Button variant="link" style={{textAlign:"left", color: "white"}} onClick={ ()=>{ goTo("about") } } >{getStr("footer_about_us")}</Button>
             <Button variant="link" style={{textAlign:"left", color: "white"}} onClick={ ()=>{ goTo("contact") } }>{getStr("footer_about_contact")}</Button>
             <Button variant="link" style={{textAlign:"left", color: "white"}} onClick={ ()=>{ goTo("regulation") } }>{getStr("footer_about_terms_and_conditions")}</Button>
             <Button variant="link" style={{textAlign:"left", color: "white"}} onClick={ ()=>{ goTo("privacy_policy") } }>{getStr("footer_about_privacy_policy")}</Button>
          </Stack>
        </Col>
        <Col sm>
          <p style={{textAlign:"center"}}>
             {getConfigValue("appName") + " " + new Date().getFullYear()}
          </p>
        </Col>
        <Col sm>
          {isFeatureAvailable("mobileAppsAvailable")?<div>
            <img
              src="../images/app_store.png"
              width="128"
              height="38"
              alt="app_store"
              onClick={onAppStoreClicked}
            />
            <img
              src="../images/google_play.png"
              width="128"
              height="38"
              alt="app_store"
              style={{marginLeft: "16px"}}
              onClick={onGooglePlayClicked}
            />
          </div>:null}
        </Col>
      </Row>
    </Container>
  );
}