import Parse from "parse";
import { logoutUser, isUserLoggedIn } from "../services/user"
import { getMenuConfig } from "../services/config"
import { isFeatureAvailable } from "../custom/config"
import { isSafari } from "../services/helper"


let cacheDuration = 60

function canMakeRequest(functionName) // internal ddos system
{
  if (!window.localStorage.getItem(functionName + "_ddos_timestamp"))
  {
    window.localStorage.setItem(functionName + "_ddos_timestamp", Date.now())
    return true
  }
  else 
  {
    if (Number(window.localStorage.getItem(functionName + "_ddos_timestamp")) + 1000 < Date.now() === true)
    {
      window.localStorage.setItem(functionName + "_ddos_timestamp", Date.now())
      return true
    }
    else 
    {
      return false
    }
  }
}

export const setCacheDuration = async(value) =>
{
  cacheDuration = value
  // console.log(cacheDuration)
}

function isCacheActive(name, duration)
{
  const _timestamp = Number(window.localStorage.getItem(name))
  const current_time = Date.now();
  if (_timestamp && (_timestamp + duration) > current_time/1000) // cache is still on
  {
    return true
  }
  else  
  {
    const current_time = Date.now();
    window.localStorage.setItem(name, current_time/1000) // lat that app was fully new loaded
    return false
  }
}

function clearCache(name)
{
  window.localStorage.setItem(name, 0)
}

export const clearAllCachedListPages = async(name) =>
{
  const mainMenu = await getMenuConfig()
  for (let i = 0; i < mainMenu.length; i ++)
  {
    window.localStorage.setItem("getListsPageTimestamp_" + mainMenu[i].codename, 0)
    window.localStorage.setItem("configTimestamp", 0)
  }
}

async function handleError(error_code)
{
  if (error_code === 209)
  {
    logoutUser()
  }
}

////////////// API calls for data

export const getAssetPage = async(assetObjectId) => 
{
  try 
  {
    if (canMakeRequest("getAssetPage"))
    {
      console.log("getAssetPage")
      return await Parse.Cloud.run("getAssetPage", {objectId: assetObjectId})
    }
    else 
    {
      return false
    }
  } 
  catch (error) 
  {
    return {"isError":true, "code":error.code}
  }
}

export const getListsPage = async(pageCodename) => 
{
  try 
  {
    const items = window.localStorage.getItem("getListsPage_" + pageCodename);
    if (isCacheActive("getListsPageTimestamp_" + pageCodename, cacheDuration) === true && items)
    {
      return JSON.parse(items);
    }
    else  
    {
      if (canMakeRequest("getListsPage"))
      {
        let getListsPageParams = {
          pageCodename: pageCodename
        }
        const userProfile = await getUserProfile()
        if (userProfile)
        {
          getListsPageParams.userProfileObjectId = userProfile.id?userProfile.id:userProfile.objectId
        }
        const result = await Parse.Cloud.run("getListsPage", getListsPageParams)
        window.localStorage.setItem("getListsPage_" + pageCodename, JSON.stringify(result));
        return result
      }
      else 
      {
        return []
      }
    }
  } 
  catch (error) 
  {
    console.log(error)
    await handleError(error.code)
    return {"isError":true, "code":error.code}
  }
}

export const getSingleListPage = async(listObjectId, filterByCategory, sort) => 
{
  try 
  {
    if (!filterByCategory) { filterByCategory = "default" }
    if (!sort) { sort = "default" }

    const result = window.localStorage.getItem("getSingleListPage_" + listObjectId + "_" + filterByCategory + "_" + sort);
    if (isCacheActive("getSingleListPageTimestamp_" + listObjectId + "_" + filterByCategory + "_" + sort, cacheDuration) === true && result)
    {
      return JSON.parse(result);
    }
    else  
    {
      if (canMakeRequest("getSingleListPage"))
      {
        let getListsPageParams = {
          listObjectId: listObjectId,
          filterByCategory: filterByCategory,
          sort: sort
        }
        const userProfile = await getUserProfile()
        if (userProfile)
        {
          getListsPageParams.userProfileObjectId = userProfile.id?userProfile.id:userProfile.objectId
        }
        const result = await Parse.Cloud.run("getSingleListPage", getListsPageParams)
        window.localStorage.setItem("getSingleListPage_" + listObjectId, JSON.stringify(result));
        return result
      }
      else 
      {
        return []
      }
    }
  } 
  catch (error) 
  {
    console.log(error)
    await handleError(error.code)
    return {"isError":true, "code":error.code}
  }
}

export const getChannelsPage = async() => 
{
  try 
  {
    const result = window.localStorage.getItem("getChannelsPage");
    if (isCacheActive("getChannelsPageTimestamp", cacheDuration) === true && result)
    {
      return JSON.parse(result);
    }
    else  
    {
      if (canMakeRequest("getChannelsPage"))
      {
        let getListsPageParams = {}
        const userProfile = await getUserProfile()
        if (userProfile)
        {
          getListsPageParams.userProfileObjectId = userProfile.id?userProfile.id:userProfile.objectId
        }
        const result = await Parse.Cloud.run("getChannelsPage", getListsPageParams)
        window.localStorage.setItem("getChannelsPage", JSON.stringify(result));
        return result
      }
      else  
      {
        return {}
      }
    }
  } 
  catch (error) 
  {
    console.log(error)
    await handleError(error.code)
    return {"isError":true, "code":error.code}
  }
}


export const getMainMediafileData = async(objectId, whatType) => 
{
  if (canMakeRequest("getMainMediafileData"))
  {
    let params = {
      objectId: objectId
    }
    if (whatType)
    {
      params.whatType = whatType
    }
    if (isSafari())
    {
      params.isSafariBrowser = true
    }
    const rerutnData = await Parse.Cloud.run("getMainMediafileData", params)
    // save in local storage rerutnData.watchSessionObjectId
    if (rerutnData.watchSessionObjectId)
    {
      window.localStorage.setItem("currentWatchSessionObjectId", rerutnData.watchSessionObjectId);
    }
    else 
    {
      window.localStorage.removeItem("currentWatchSessionObjectId")
    }
    return rerutnData
  }
  else 
  {
    return {}
  }
};

export const getSubMediafileData = async(assetObjectId) => 
{
  if (canMakeRequest("getSubMediafileData"))
  {
    let params = {
      assetObjectId: assetObjectId
    }
    if (isSafari())
    {
      params.isSafariBrowser = true
    }
    return await Parse.Cloud.run("getSubMediafileData", params)
  }
  else 
  {
    return {}
  }
};

export const updateWatchSession = async(data) => 
{
  if (canMakeRequest("updateWatchSession"))
  {
    return await Parse.Cloud.run("updateWatchSession", data)
  }
  else 
  {
    return {}
  }
};

export const getProducts = async(whatType, objectIds) => 
{
  if (canMakeRequest("getProducts"))
  {
    try 
    {
      const Product = Parse.Object.extend("Product");
      const queryProduct = new Parse.Query(Product);
      queryProduct.equalTo("isActive", true)
      if (whatType)
      {
        queryProduct.equalTo("whatType", whatType)
      }
      if (objectIds)
      {
          queryProduct.containedIn("objectId", objectIds);
      }
      const resultsProduct = await queryProduct.find()
      console.log(resultsProduct)
      let items = []
      for (let i = 0; i < resultsProduct.length; i++)
      {
        items.push({
          name: resultsProduct[i].get("name"),
          description: resultsProduct[i].get("description"),
          objectId: resultsProduct[i].id,
          currency: resultsProduct[i].get("currency"),
          price: resultsProduct[i].get("price") / 100,
        })
      }

      return items
    } 
    catch (error) 
    {
      await handleError(error.code)
      return []
    }
  }
  else 
  {
    console.log("wait!!!")
    return []
  }
};

export const getUserPurchasedProducts = async(refresh) => 
{
    try 
    {
      const items = window.localStorage.getItem("getUserPurchasedProducts");
      if (!refresh && isCacheActive("getUserPurchasedProductsTimestamp", cacheDuration) === true && items)
      {
        return JSON.parse(items);
      }
      else  
      {
        if (canMakeRequest("getUserPurchasedProducts"))
        {
          const user_products = await Parse.Cloud.run("getUserPurchasedProducts")
          window.localStorage.setItem("getUserPurchasedProducts", JSON.stringify(user_products));
          return user_products
        }
      }
    } 
    catch (error) 
    {
      await handleError(error.code)
      return []
    }
};

export const getUserProfile = async() => 
{
    try 
    {
      const item = window.localStorage.getItem("getUserProfile");
      if (item)
      {
        return JSON.parse(item);
      }
      else  
      {
        if (isUserLoggedIn() === true)
        {
          if (canMakeRequest("getUserProfile"))
          {
            const UserProfile = Parse.Object.extend("UserProfile");
            const query = new Parse.Query(UserProfile);
            query.include("recommendation")
            const items = await query.find()
            if (items.length > 0)
            {
              window.localStorage.setItem("getUserProfile", JSON.stringify(items[0]));
              window.localStorage.setItem("user_selected_language", items[0].get("uiLanguage"))
              return items[0]
            }
            else  
            {
              return {}
            }
          }
          else 
          {
            return {}
          }
        }
        else 
        {
          return {}
        }
      }
    } 
    catch (error) 
    {
      await handleError(error.code)
      return {}
    }
};

export const getCategories = async() => 
{
    try 
    {
      const items = window.localStorage.getItem("getCategories");
      if (items)
      {
        return JSON.parse(items);
      }
      else  
      {

          if (canMakeRequest("getCategories"))
          {
            const Category = Parse.Object.extend("Category");
            const query = new Parse.Query(Category);
            query.equalTo("whatType","category")
            const _items = await query.find()
            let _array_items = []
            for (let i = 0; i < _items.length; i++)
            {
              _array_items.push({
                name: _items[i].get("name"),
                objectId: _items[i].id
              })
            }
            if (_array_items.length > 0)
            {
              window.localStorage.setItem("getCategories", JSON.stringify(_array_items));
              return _array_items
            }
            else  
            {
              return []
            }
          }
          else 
          {
            return []
          }
      }
    } 
    catch (error) 
    {
      await handleError(error.code)
      return {}
    }
};

export const getSearchResults = async(searchPhrase) => 
{
  if (canMakeRequest("getSearchResults"))
  {
    try 
    {
        return await Parse.Cloud.run("getSearchPage", {searchPhrase: searchPhrase})
    } 
    catch (error) 
    {
      await handleError(error.code)
      return []
    }
  }
  else
  {
    return []
  }
};

export const setUserProfile = async(userProfileObjectId) => 
{
  if (canMakeRequest("setUserProfile"))
  {
    try 
    {
        const UserProfile = Parse.Object.extend("UserProfile");
        const query = new Parse.Query(UserProfile);
        query.include("recommendation")
        const item = await query.get(userProfileObjectId)
        if (item)
        {
          window.localStorage.setItem("getUserProfile", JSON.stringify(item));
          return item
        }
        else  
        {
          return []
        }
    } 
    catch (error) 
    {
      await handleError(error.code)
      return []
    }
  }
};

export const getUserTransactionStatus = async(transactionObjectId) => 
{
  if (canMakeRequest("getUserTransactionStatus"))
  {
    try 
    {
      return await Parse.Cloud.run("getUserTransactionStatus", {objectId: transactionObjectId})
    } 
    catch (error) 
    {
      await handleError(error.code)
      return false
    }
  }
  else  
  {
    return []
  }
};

export const buyProduct = async(productObjectId, assetObjectId) => 
{
  if (canMakeRequest("buyProduct"))
  {
    try 
    {
      clearCache("getUserPurchasedProductsTimestamp") // clear cache so new transactions will be downloaded
      const result = await Parse.Cloud.run("buyProduct", {productObjectId: productObjectId, assetObjectId: assetObjectId})
      console.log(result)
      if (result.url)
      {
        if (assetObjectId)
        {
          window.localStorage.setItem("lastVisitedAssetObjectId", assetObjectId);
        }
        window.localStorage.setItem("lastTransactionObjectId", result.transactionObjectId); // save this ID to check status on thank you page.
        window.open(result.url, '_self');
      }
      return result
    }
    catch (error)
    {

    }
  }
  else  
  {
    return []
  }
};


export const isIpAllowed = async() => 
{
  if (isFeatureAvailable("useIpRestriction"))
  {
    if (canMakeRequest("buyProduct"))
    {
      try 
      {
        const result = await Parse.Cloud.run("isIpAllowed")
        return result.isAllowed
      } 
      catch (error) 
      {
        await handleError(error.code)
        return false
      }
    }
    else  
    {
      return []
    }
  }
  else  
  {
    return true
  }
};


// ADMIN
export const addIpRange = async() => 
{
    try 
    {
      return await Parse.Cloud.run("addIpRange",{startIpRange: "91.211.255.200" ,stopIpRange: "91.211.255.255"})
    } 
    catch (error) 
    {
      await handleError(error.code)
      return false
    }
};
