import styled from "styled-components"
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {main_font, category_font_size, listCategory, listCategoryMore} from "../styles/globalstyle";
import getStr from "../data/strings";

const Container = styled.div`
	margin-top: 16px;
	margin-bottom: 4px;
`
const Name = styled.h2`
    font-size: ${listCategory.fontSize};
`
const Info = styled.div`
 	font-family: ${main_font};
    font-size: ${listCategoryMore.fontSize};
    font-weight: 600;
    margin-top: 20px;
    color: rgba(250, 250, 250, 255);
    &:hover {
		cursor: pointer;
	};
`

export function Category({name, objectId, goToList})
{
	return <>
		<Container onClick={()=>{ if(objectId) { { goToList(objectId) } }} }>
			<Row className="justify-content-md-center">
		        <Col md="auto">
		          <Name>{name}</Name>
		        </Col>
		        <Col ></Col>
		        <Col md="auto">
		          {objectId?<Info>{getStr("list_category_show_more")}</Info>:null}
		        </Col>
	      	</Row>
		</Container>
</>
}