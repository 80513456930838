import { useState, useEffect} from "react";
import { useNavigate  } from "react-router-dom"
import { getConfig } from "../services/config"

import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';

export default function Start() 
{
  const navigate = useNavigate()  
  async function init()
  {
    await getConfig()
    navigate("/lists/home")
  }

  useEffect(() => {
    init()
  }, [])

  return (
    <Container>
      <div>
        <img
          src="../custom_images/logo_splash.png"
          width="120"
          height="120"
          // className="d-inline-block align-top"
          alt="logo"
          style={{display: "block", marginLeft: "auto", marginRight: "auto", marginTop: "256px"}}
        />
        <Spinner animation="grow" style={{display: "block", marginLeft: "auto", marginRight: "auto", marginTop: "16px", color:"red", backgroundColor:"red"}}/>
      </div>
    </Container>
  );
}