import { useState, useEffect} from "react";
import { useNavigate } from "react-router-dom"
import { NavBar } from "../components/navbar"
import { Footer } from "../components/footer"
import { GridList } from "../components/grid"
import { getSearchResults } from "../services/api"
import Container from 'react-bootstrap/Container';
import { getConfigValue } from "../custom/config"
import Form from 'react-bootstrap/Form';
import { Loader } from "../components/loader"
import getStr from "../data/strings";

export default function ListsPage() 
{
  const navigate = useNavigate();
  const [list, setList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [searchSafeTimer, setSearchSafeTimer] = useState()
  const [noResultsTest, setNoResultsTest] = useState("")


  async function search(phrase)
  {
    setNoResultsTest("")
    const list_data = await getSearchResults(phrase)
    setList(list_data)
    setIsLoading(false)

    if (phrase.length > 2)
    {
      if (list_data.items.length > 0)
      {
        setNoResultsTest("")
      }
      else 
      {
        setNoResultsTest(getStr("search_no_results_info") + phrase + "'")
      } 
    }
  }

  function onSearchChange(event)
  {
    event.preventDefault()
    clearTimeout(searchSafeTimer);
    setList([])
    setSearchSafeTimer(setTimeout(function(){
      if (event.target.value.length > 2)
      {
        search(event.target.value)
      }
    }, 1000) )

    if (event.target.value.length > 2)
    {
      setIsLoading(true)
    }
    else 
    {
      setIsLoading(false)
      setNoResultsTest("")
    }
  }


  function goToAsset(object_id)
  {
    navigate("/asset/"+object_id)
  }

  useEffect(() => 
  {
    document.getElementById("search-input").focus()
  }, [])

  return (
    <>
      <NavBar hideSearch={true}/>
      <Container style={{minHeight:"960px", marginTop:"16px"}}>
        <Form.Control id="search-input" size="lg" type="text" placeholder={getStr("search_field_info")} onChange={onSearchChange} style={{marginBottom:"32px"}} />
        {isLoading?<Loader/>:null}
        {noResultsTest!==""?<h1>{noResultsTest}</h1>:null}
        <GridList list={list} onClickCallback={goToAsset} />
      </Container>
      <Footer/>
    </>
  );
}