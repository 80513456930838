// import returnStrPL from '../data/strings_pl';
import returnStrEN from '../data/strings_en';

export default function getStr(key) 
{
  const language = window.localStorage.getItem("user_selected_language")
  if (language === "EN")
  {
    return returnStrEN(key)
  }
  // else if (language === "PL")
  // {
  //   return returnStrPL(key)
  // }
};


