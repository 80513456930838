import Parse from 'parse';
import styled from "styled-components"
import { useState, useEffect} from "react";
import { useNavigate, useParams  } from "react-router-dom"
import { getConfig } from "../services/config"
import { NavBar } from "../components/navbar"
import { Footer } from "../components/footer"

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Loader } from "../components/loader"



export default function StaticText() 
{
  const { page_id } = useParams();
  const navigate = useNavigate();

  const [title, setTitle] = useState("");
  const [isLoading, setIsLoading] = useState(false);


  async function setPage(page_id)
  {
    const Page = Parse.Object.extend("Page");
    const query = new Parse.Query(Page);
    query.equalTo("codename", page_id)
    const results = await query.find()
    setTitle(results[0].get("title")) 
    document.getElementById("content").innerHTML = results[0].get("content")
  }

  function refreshPage()
  {
    window.location.reload(false);
  }

  useEffect(() => 
  {
    console.log(page_id)
    if (page_id)
    {
      setPage(page_id) 
    }

    window.addEventListener('popstate', refreshPage, false);
    return () => {
      window.removeEventListener('popstate', refreshPage)
    }
  }, [])

  return (
    <>
      <NavBar/>
      <Container style={{minHeight:"640px"}}>
        <h1>{title}</h1>
        <p id="content"></p>
      </Container>
      <Footer onStaticPageClickCallback={refreshPage}/>
    </>
  );
}